import { useAmpliError } from '@/hooks/use-ampli-error';
import styles from '@/styles/error.module.scss';
import { Button, useMediaQuery } from '@mui/material';
import Link from 'next/link';
import { useEffect } from 'react';
import errorImg from '@/assets/images/errors/errors_image.svg';
import Image from 'next/image';
import fourImg from '@/assets/images/errors/4.svg';
import lineUpImage from '@/assets/images/errors/lineUp.svg';
import lineBottomImage from '@/assets/images/errors/lineBottom.svg';
import lineUpMobile from '@/assets/images/errors/lineUpMobile.svg';
import lineBottomMobile from '@/assets/images/errors/lineBottomMobile.svg';
import { theme } from '@/styles/theme';
import { track } from '@amplitude/analytics-browser';
import { useAuthContext } from '@/hooks/use-auth-context';
import { captureEvent } from '@/utils/analytics';

const NotFoundPage = () => {
  const { errorTrack } = useAmpliError();
  const { isAuthorized } = useAuthContext();

  const mobile = useMediaQuery(theme.breakpoints.down(599));

  useEffect(() => {
    track('open page', {
      subevent: '404',
      auser: isAuthorized,
    });
    captureEvent('open page', { subevent: '404', auser: isAuthorized });
    errorTrack({ message: 'Страница не найдена (404)', isAuthorized });
  }, []);

  return (
    <div className={styles.container_errors}>
      {!mobile ? (
        <Image src={lineUpImage} alt="" />
      ) : (
        <Image src={lineUpMobile} alt="" />
      )}

      <div className={styles.main}>
        <div className={styles.main_images}>
          <Image src={fourImg} alt="" />
          <Image src={errorImg} alt="" width="263px" height="288px" />
          <Image src={fourImg} alt="" />
        </div>
        <div className={styles.main_block}>
          <p className={styles.main_title}>
            Похоже, вы потерялись. Вернитесь на главную страницу.
          </p>
          <Link href="/" passHref>
            <Button
              className={styles.main_button}
              variant="contained"
              color="mainGreen"
            >
              Перейти на главную
            </Button>
          </Link>
        </div>
      </div>
      {!mobile ? (
        <Image src={lineBottomImage} alt="" />
      ) : (
        <Image src={lineBottomMobile} alt="" />
      )}
    </div>
  );
};

export default NotFoundPage;
